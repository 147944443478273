import React from 'react';
import { useTranslation } from 'react-i18next';
import visa from '../public/img/visa.svg';
import mastercard from '../public/img/mastercard.svg';
import paypal from '../public/img/paypal.svg';
import postepay from '../public/img/postepay.svg';
import amex from '../public/img/amex.svg';
import StyledImage from './StyledImage';
import packageInfo from '../package.json';
import { STRING_LANG } from '../helpers/languages';
import FacebookLink from './FacebookLink';
import instagram from '../public/img/instagram.svg';
import Link from 'next/link';

const POLICY_ID = 'IUBENDA.POLICY_ID';

const FooterLinks = ({
  lang,
  country
}: {
  lang: STRING_LANG;
  country: string;
}) => {
  const { t } = useTranslation();
  const showIubenda =
    process.env.ENV !== 'staging' && process.env.NEXT_PUBLIC_ENV !== 'staging';

  return (
    <div className="mt-8 border-t border-white border-opacity-10 pt-8 md:flex md:items-center md:justify-between">
      <div className="flex space-x-6 md:order-3">
        <StyledImage src={visa} className="mr-1" alt="visa logo" />
        <StyledImage src={mastercard} className="mr-1" alt="mastercard logo" />
        <StyledImage src={paypal} className="mr-1" alt="paypal logo" />
        <StyledImage src={postepay} className="mr-1" alt="postepay logo" />
        <StyledImage src={amex} alt="american express logo" />
      </div>
      <div className="text-sm text-white flex flex-col space-x-0 space-y-1 sm:space-y-0 sm:space-x-6 md:order-2 mt-8 md:mt-0 sm:flex-row font-normal">
        <Link
          href={`/${lang}_${country}/terms-and-conditions`}
          className="text-white opacity-40"
        >
          {t('TERMS_AND_CONDITIONS')}
        </Link>
        <a
          href={`https://www.iubenda.com/privacy-policy/${t(POLICY_ID)}`}
          className="iubenda-white no-brand iubenda-noiframe iub-body-embed text-white opacity-40"
          title="Privacy Policy"
          target="_blank"
          rel="noreferrer"
        >
          {t('PRIVACY_TITLE')}
        </a>

        <a
          href={`https://www.iubenda.com/privacy-policy/${t(
            POLICY_ID
          )}/cookie-policy`}
          className="iubenda-white no-brand iubenda-noiframe iub-body-embed text-white opacity-40"
          title="Cookie Policy"
          target="_blank"
          rel="noreferrer"
        >
          {t('COOKIE_TITLE')}
        </a>

        {/* IUBENDA CONSENT POPUP */}
        {showIubenda && (
          <>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
                var _iub = _iub || [];
                _iub.csConfiguration = {
                  "countryDetection":true,
                  "enableFadp":true,
                  "enableUspr":true,
                  "floatingPreferencesButtonDisplay":"bottom-right",
                  "perPurposeConsent":true,
                  "siteId":${t('IUBENDA.SITE_ID')},
                  "whitelabel":false,
                  "cookiePolicyId":${t(POLICY_ID)},
                  "lang":"${t('IUBENDA.LANG')}", 
                  "banner":{ 
                    "acceptButtonCaptionColor":"#18170d",
                    "acceptButtonColor":"#efbd06",
                    "acceptButtonDisplay":true,
                    "backgroundColor":"#18170d",
                    "closeButtonRejects":true,
                    "customizeButtonColor":"#323226",
                    "customizeButtonDisplay":true,
                    "explicitWithdrawal":true,
                    "listPurposes":true,
                    "position":"float-bottom-center",
                    "rejectButtonColor":"#323226",
                    "rejectButtonDisplay":true 
                  }
                };`
              }}
            />
            <script
              type="text/javascript"
              src="//cdn.iubenda.com/cs/iubenda_cs.js"
              async
            ></script>

            {/* IUBENDA COOKIE policies */}
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                  `
              }}
            />
            {/* PRIVACY policies */}
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                  `
              }}
            />
          </>
        )}

        <p className="text-white opacity-40">v. {packageInfo.version}</p>
      </div>
      <div>
        <div className="flex mt-3 sm:mt-0">
          <FacebookLink />
          <a
            href="https://www.instagram.com/jeepoutfitter/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <StyledImage
              src={instagram}
              alt="instagram logo"
              className="flex w-3"
            />
            <p className="font-normal text-sm ml-2 text-white">Instagram</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;
